export default {
  name: "home",
  redirect: "results",
  component: () =>
    import(/** webpackChunkName: "home" */ "../components/Layout.vue"),
  children: [
    {
      path: "results",
      name: "results",
      component: () =>
        import(/** webpackChunkName: "home" */ "../views/Results.vue"),
    },
  ],
};
